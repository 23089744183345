import React, { useState } from 'react';
import './CloudCapacityManagementTable.less';
import { Popover, Progress, Spin, Table, Tag } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleTwoTone,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { green, blue, gold } from '@ant-design/colors';
import ClusterSizeCellModal from '../ClusterSizeCellModal/ClusterSizeCellModal';
import {
  useClusterSizesPerCloudProviders,
  useCreateNewClusterSize,
} from 'hooks/capacityManagementQueries';
import {
  ClusterSizesPerClusterCodeItem,
  OperatorPerPlatformItem,
} from 'types/capacityManagement.types';

function CloudCapacityManagementTable({
  operatorsPerPlatform,
  cloudProviderID,
  cloudProviderKey,
  showModalForNewSize,
  setShowModalForNewSize,
}: {
  operatorsPerPlatform: OperatorPerPlatformItem[];
  cloudProviderID: number;
  cloudProviderKey: string;
  showModalForNewSize: boolean;
  setShowModalForNewSize: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const openModalForNewSize = showModalForNewSize && !!cloudProviderID;

  const {
    data: clusterSizesPerCloudProviders,
    isLoading: isClusterSizesPerCloudProvidersLoading,
  } = useClusterSizesPerCloudProviders({
    cloudProviderID: cloudProviderID.toString(),
  });

  const [showCellEditModal, setShowCellEditModal] = useState(false);
  const [clickedCellValue, setClickedCellValue] = useState<
    ClusterSizesPerClusterCodeItem & { operatorAlias: string }
  >();

  const {
    mutateAsync: mutateCreateNewClusterSize,
    isLoading: isCreateNewClusterSizeLoading,
  } = useCreateNewClusterSize();
  async function handleFillSize({ currentClusterCode, record }) {
    const newClusterSize = {
      name: record.name,
      displayName: record.displayName,
      overCommitment: record.overCommitment,
      cloudProviderID: record.regionProvider.cloudProviderID,
      rolesIDs: record.roles.map(role => role.role.id),
      operatorClusterCodes: [
        {
          code: currentClusterCode,
          label: record.nodeLabel,
          cpu: record.cpu,
          memoryRequest: record.memoryRequest,
          memoryLimit: record.memoryLimit,
          memoryText: record.memoryText,
        },
      ],
    } as any;
    await mutateCreateNewClusterSize({
      newClusterSize,
    });
  }

  const columns: ColumnsType<any> = [
    {
      title: (
        <article className="cloud-capacity-management-table__size-field-header">
          Size
          <Popover
            content={
              <div>
                <ul>
                  <li>
                    Only <b>main sizes</b> from <b>xsmall</b> to <b>2xlarge</b>{' '}
                    will be available in Cloud Portal while creating a cluster.
                  </li>
                  <li>
                    <b>Main sizes</b> from <b>xsmall</b> to <b>10xlarge</b>{' '}
                    cannot be deleted.
                  </li>
                  <li>
                    Sizes <b>other than the main sizes</b> can be deleted only
                    if they are{' '}
                    <b>
                      <i>not</i>
                    </b>{' '}
                    used in a cluster.
                  </li>
                </ul>
              </div>
            }
          >
            <InfoCircleTwoTone
              twoToneColor={gold[5]}
              color={gold[7]}
              style={{ color: gold[5] }}
            />
          </Popover>
        </article>
      ),
      dataIndex: 'size',
      fixed: 'left',
      width: 150,
      // onCell: (record, index) => {
      //   const sizeIndexInDataSource = clusterSizesPerCloudProviders.findIndex(
      //     sizeItem => sizeItem.size === record.size,
      //   );
      //   return {
      //     style: {
      //       fontWeight: sizeIndexInDataSource < 6 ? 'bold' : 'normal',
      //     },
      //   };
      // },
      render(value, record, index) {
        const sizeIndexInDataSource = clusterSizesPerCloudProviders.findIndex(
          sizeItem => sizeItem.size === value,
        );
        return (
          <section className="cloud-capacity-management-table__size-cell">
            <article className="cloud-capacity-management-table__size-cell-label">
              {value}
            </article>
            {/* <Popover
              content={
                <div>
                  <h4>Shown Size</h4>
                  <ul>
                    <li>
                      The first 6 sizes are the only shown in Cloud Portal while
                      creating a new cluster
                    </li>
                    <li>
                      In order to show a cluster size in Cloud Portal, drag it
                      into the first 6 rows
                    </li>
                  </ul>
                </div>
              }
            >
              <Badge
                count={
                  sizeIndexInDataSource < 6 ? (
                    <InfoCircleTwoTone
                      twoToneColor={green[5]}
                      color={green[7]}
                      style={{ color: green[5] }}
                    />
                  ) : null
                }
              />
            </Popover> */}
          </section>
        );
      },
    },
    ...(operatorsPerPlatform || [])?.map(operator => ({
      title: (
        <article>
          <label>{operator.clusterCode}</label>
        </article>
      ),
      align: 'center' as any,
      dataIndex: operator.clusterCode,
      width: 500,
      className: `cloud-capacity-management-table__memory-cell-wrapper table-cell ${operator.clusterCode}`,
      render(cellValue, { size }, index) {
        if (!cellValue && isCreateNewClusterSizeLoading) {
          return <Spin />;
        }
        const regionRecord = cellValue;
        if (!cellValue) {
          return (
            <>
              Create Size "<b>{size}</b>" in operator{' '}
              <b>"{operator.clusterCode}"</b>
            </>
          );
        }
        const memoryRequest = regionRecord.memoryRequest;
        const memoryLimit = regionRecord.memoryLimit;
        const memoryText = regionRecord.memoryText;
        const isRequestExceeded = memoryRequest > memoryLimit;
        const requestPercent = Math.round((memoryRequest / memoryLimit) * 100);
        const isOvercommitmentIncluded = !regionRecord.overCommitment.excluded;

        return (
          <section className="cloud-capacity-management-table__memory-cell-card-wrapper">
            <section className="cloud-capacity-management-table__memory-cell-card">
              <label className="cloud-capacity-management-table__memory-cell-card-header">
                Memory
              </label>
              <section className="cloud-capacity-management-table__memory-cell-card-progress-wrapper">
                <label
                  style={{ color: green[7] }}
                  className="cloud-capacity-management-table__memory-cell-card-title"
                >
                  Request
                </label>
                <Progress
                  size="small"
                  percent={isRequestExceeded ? 100 : requestPercent}
                  format={() => (
                    <article
                      className="cloud-capacity-management-table__memory-cell-card-data"
                      style={{ color: green[6] }}
                    >
                      <label className="cloud-capacity-management-table__cell-value">{`${memoryRequest}`}</label>
                      <p className="cloud-capacity-management-table__cell-unit">
                        {' '}
                        GB
                      </p>
                    </article>
                  )}
                  strokeColor={{
                    '0%': green[4],
                    '100%': green[6],
                  }}
                  trailColor={blue[1]}
                />
              </section>
              <section className="cloud-capacity-management-table__memory-cell-card-progress-wrapper">
                <label
                  style={{ color: blue[7] }}
                  className="cloud-capacity-management-table__memory-cell-card-title"
                >
                  Limit
                </label>
                <Progress
                  size="small"
                  percent={isRequestExceeded ? 100 - requestPercent : 100}
                  format={() => (
                    <article
                      className="cloud-capacity-management-table__memory-cell-card-data"
                      style={{ color: blue[6] }}
                    >
                      <label className="cloud-capacity-management-table__cell-value">{`${memoryLimit}`}</label>
                      <p className="cloud-capacity-management-table__cell-unit">
                        {' '}
                        GB
                      </p>
                    </article>
                  )}
                  strokeColor={{
                    '0%': blue[3],
                    '100%': blue[7],
                  }}
                  trailColor={blue[1]}
                />
              </section>
            </section>
            <Tag
              className="cloud-capacity-management-table__overcommitment-cell-tag"
              icon={
                isOvercommitmentIncluded ? (
                  <CheckCircleOutlined />
                ) : (
                  <CloseCircleOutlined />
                )
              }
              color={isOvercommitmentIncluded ? 'green' : 'red'}
            >
              Over Commitment
            </Tag>
          </section>
        );
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: (e: any) => {
            const isFillingSizeInOperator = !record[operator.clusterCode];
            if (isFillingSizeInOperator) {
              // const s = record[clusterCode];
              const anyAlreadyFilledOperatorName = Object.keys(record).find(
                rec => rec !== 'size',
              );
              const itsRecord = record[anyAlreadyFilledOperatorName];

              handleFillSize({
                currentClusterCode: operator.clusterCode,
                record: itsRecord,
              });

              // const cellValue = {
              //   ...record[operator.clusterCode],
              //   operatorAlias: operator.clusterCode,
              //   clusterCode: operator.clusterCode,
              // };
              // setClickedCellValue(cellValue);
              // setShowCellEditModal(true);
              // // setShowModalForNewSize(true);
            } else {
              const cellValue = {
                ...record[operator.clusterCode],
                operatorAlias: operator.clusterCode,
                clusterCode: operator.clusterCode,
              };
              setShowCellEditModal(true);
              setClickedCellValue(cellValue);
            }
          },
        };
      },
    })),
  ];

  return (
    <>
      <Table
        rowKey="size"
        className="antd-table-customized-scroll"
        bordered
        dataSource={clusterSizesPerCloudProviders}
        columns={columns}
        pagination={false}
        scroll={{ x: 1500 }}
        sticky
        // loading={isReorderClusterSizeLoading}
      />

      {showCellEditModal ? (
        <ClusterSizeCellModal
          clusterSizeData={clickedCellValue}
          cloudProviderKey={cloudProviderKey}
          close={() => setShowCellEditModal(false)}
        />
      ) : openModalForNewSize ? (
        <ClusterSizeCellModal
          cloudProviderID={cloudProviderID}
          cloudProviderKey={cloudProviderKey}
          close={() => setShowModalForNewSize(false)}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default CloudCapacityManagementTable;
